<template>
  <div class="container">
    <div class="head">
      <div class="left">
        <div class="ico-left"><i class="el-icon-message-solid"></i></div>
        <div class="color-red" v-if="dateConfig.isShow">
          {{ dateConfig.activity_name }}
        </div>
        <div class="color-red" v-else>日常控价</div>
        <div class="color-black">当前控价</div>
      </div>
      <div class="mid">
        <div class="ico-left"><i class="el-icon-message-solid"></i></div>
        <div class="color-red" v-if="dateConfig.isShow">
          {{ dateConfig.start_date }} ~ {{ dateConfig.end_date }}
        </div>
        <div class="color-red" v-else>日常</div>
        <div class="color-black">活动时间段</div>
      </div>
      <div class="left right">
        <i class="el-icon-paperclip ico-right"></i>
        <div class="color-black">
          所有<span style="color: #f75451">商品到手价</span>
        </div>
        <div class="color-red" v-if="dateConfig.isShow">
          不能低于活动期间控价
        </div>
        <div class="color-red" v-else>不能低于日常控价</div>
        <div class="color-black">感谢各位商户配合!</div>
      </div>
    </div>
    <div class="select">
      <div class="brand">
        品牌：
        <el-select v-model="brandValue" clearable placeholder="请选择">
          <el-option
            v-for="item in brandOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="brand" style="margin-left: 30px">
        分类：
        <el-select clearable v-model="typeValue" placeholder="请选择">
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="export"> -->
      <div
        class="input"
        style="display: flex; align-items: center; margin-left: 30px"
      >
        <span>关键字：</span>
        <el-input v-model="input" placeholder="商品条码/商品名称"></el-input>
      </div>
      <el-button type="primary" @click="getProductList('nopage')"
        >查询</el-button
      >
      <!-- <el-button type="info" @click="productExport">导出</el-button> -->
      <!-- <div class="loadBox">
                <el-link type="primary" @click="loadHY">荒野盛宴控价表下载</el-link>
                <el-link type="primary" style="margin-left:80px" @click="loadFM">福摩控价表下载</el-link>
            </div> -->

      <!-- </div> -->
    </div>
    <!-- <div v-if="dateConfig.isShow"
            style="width: 100%;  margin-top: 20px; display: flex; align-items: center; color: #f53434;">
            <div style="display: flex;  align-items: center;"><img :src="msg" style="width: 50px;" alt="">
                <span style="color: #222;">当前活动类型：</span>{{ dateConfig.activity_name || '-' }}
            </div>
            <div><span style="color: #222; margin-left: 20px; margin-left: 120px;">活动时间段：</span>{{ dateConfig.start_date
                || '-' }}至{{ dateConfig.end_date }} </div>
            <div style="margin-left: 60px; color: blue;">所有商品到手价不能低于<span style="color: #f53434;">活动期间控价</span>价格，感谢各位商户配合!
            </div>
        </div>
        <div v-else style="width: 100%;  margin-top: 20px; color: blue; display:  flex; align-items: center; "><img
                :src="msg" style="width: 50px;margin-right: 20px;" alt="">所有商品到手价不能低于<span
                style="color: #f53434;">日常控价</span>价格，感谢各位商户配合!
        </div> -->
    <div class="table">
      <el-table
        border
        :data="productList"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="cell"
        @selection-change="handleSelectionChange"
        stripe
        style="width: 100%"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="item_img_url" width="170" label="图片">
          <template slot-scope="scope">
            <el-popover placement="right" width="400" trigger="click">
              <img
                :src="scope.row.item_img_url"
                style="width: 400px; height: 400px"
                class="enlarge"
              />
              <el-button
                slot="reference"
                style="border: 0; margin-left: 0; padding: 0"
              >
                <img :src="scope.row.item_img_url" class="head_pic" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="item_name" label="名称">
          <template slot-scope="scope">
            {{ scope.row.store_name || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="cate_name" label="分类">
          <template slot-scope="scope">
            {{ scope.row.cate_name || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="brand_name" label="品牌">
          <template slot-scope="scope">
            {{ scope.row.brand_name || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="item_barcode" width="160" label="条码">
          <template slot-scope="scope">
            <div class="item-group">
              <div
                class="table-item"
                v-for="(item, inx) in scope.row.spec_info"
                :key="item.id + inx"
              >
                {{ item.attr_barcode || "-" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="single_name" label="规格">
          <template slot-scope="scope">
            <div class="item-group">
              <div
                class="table-item"
                v-for="(item, inx) in scope.row.spec_info"
                :key="item.id + inx"
              >
                {{ item.spec_name || "-" }}
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="batch_price" label="日常控价">
          <template slot-scope="scope">
            <div class="item-group">
              <div
                class="table-item"
                v-for="(item, inx) in scope.row.spec_info"
                :key="item.id + inx"
              >
                {{ item.promotion_price || "-" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="priceItem in activityConfigList"
          :key="priceItem.id"
          :width="priceItem.activity_desc.length > 5 ? '220' : ''"
        >
          <template slot="header">
            <div
              style="text-align: center; color: red"
              v-html="priceItem.activity_desc"
            ></div>
          </template>
          <template slot-scope="scope">
            <div class="item-group" style="color: red">
              <div
                class="table-item"
                v-for="(item, inx) in scope.row.spec_info"
                :key="item.id + inx"
              >
                {{ item[priceItem.desc] || "-" }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="false"
        :current-page="page"
        @current-change="curretnChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 40]"
        @prev-click="curretnChange"
        @next-click="curretnChange"
        layout="total,prev,sizes, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { urlEncode } from "@/utils/util.js";
import { BASE_URL2 } from "../request/request.config";
export default {
  name: "price",
  data() {
    return {
      tardeValue: "",
      brandValue: "",
      typeValue: "",
      input: "",
      page: 1,
      limit: 10,
      dateConfig: {},
      msg: require("@/assets/images/msg.jpg"),
      img_urls: [],
      multipleSelection: [],
      material: {},
      total: 0,
      stockOptions: [],
      brandOptions: [],
      typeOptions: [],
      tradeOptions: [],
      activityConfigList: [],
      productList: [],
    };
  },

  watch: {},
  computed: {
    shopInfo: {
      get() {
        return JSON.parse(window.localStorage.getItem("shopInfo")) || {};
      },
    },
  },
  created() {
    this.getProductList();
    this.getProductBrandList();
    this.getProductCateList();
    this.getCommonConfigList();
    this.getActivityConfigList();
  },
  methods: {
    cell({ row, column, columnIndex }) {
      if (columnIndex === 8 || columnIndex === 9) {
        return { textAlign: "center", color: "red" };
      } else {
        return { textAlign: "center" };
      }
    },
    getActivityConfigList() {
      this.$http2
        .post("/merapi/product/activity_config_list")
        .then((res) => {
          this.activityConfigList = res.data.data;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    loadFM() {
      window.open(
        "https://source.aidogcatpet.com/saas/Fromm-晟煜寵物 -销售控价表.pdf"
      );
    },
    loadHY() {
      window.open(
        "https://source.aidogcatpet.com/saas/荒野盛宴-鴻業寵物-销售控价表.pdf"
      );
    },
    prevClick(e) {
      // 点击上一页
      this.page = e;
      this.getProductList();
    },
    nextClick(e) {
      // 点击下一页
      this.page = e;
      this.getProductList();
    },
    curretnChange(e) {
      // 切换page
      this.page = e;
      this.getProductList();
    },

    handleSizeChange(e) {
      this.limit = e;
      this.getProductList();
    },
    handleSelectionChange(val) {
      // 选中商品集合
      this.multipleSelection = val;
    },
    getCommonConfigList() {
      this.$http2
        .post("/merapi/product/common_config_list")
        .then((res) => {
          this.dateConfig = res.data.data[0];
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    getProductList(e) {
      // 获取商品列表
      // eslint-disable-next-line camelcase
      var brand_id = this.brandValue;
      // eslint-disable-next-line camelcase
      var cate_id = this.typeValue;

      var keyword = this.input;
      this.page = e === "nopage" ? 1 : this.page;
      var page = this.page;
      var limit = this.limit;
      this.$http2
        .post("/merapi/product/control_price_list", {
          brand_id,
          cate_id,
          keyword,
          page,
          limit,
        })
        .then((res) => {
          this.productList = res.data.data.list;

          this.total = res.data.data.total;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    getProductBrandList() {
      // 获取品牌列表

      this.$http2
        .post("/merapi/brand/list")
        .then((res) => {
          this.brandOptions = res.data.data.list;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    getProductCateList() {
      // 获取商品分类
      this.$http2
        .post("/merapi/category/list")
        .then((res) => {
          this.typeOptions = res.data.data.list;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    getProductTradeList() {
      // 获取贸易类型
      this.$http2
        .post("/merapi/product/trade_list")
        .then((res) => {
          this.tradeOptions = res.data.data;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    getStockStatusList() {
      // 获取库存状态
      this.$http2
        .post("/merapi/product/stock_status_list")
        .then((res) => {
          this.stockOptions = res.data.data;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    productExport() {
      // 商品导出

      // eslint-disable-next-line camelcase
      var brand_id = this.brandValue;
      // eslint-disable-next-line camelcase
      var cate_id = this.typeValue;
      // eslint-disable-next-line camelcase
      var trade_type = this.tardeValue;

      var keyword = this.input;
      var multipleSelection = this.multipleSelection;
      // eslint-disable-next-line camelcase
      var ids = [];
      if (multipleSelection.length > 0) {
        multipleSelection.forEach((ele) => {
          ids.push(ele.item_id);
        });
      }
      // eslint-disable-next-line camelcase
      ids = ids.join(",");
      var token = window.localStorage.getItem("token");

      try {
        let params = {
          brand_id,
          cate_id,
          trade_type,
          stock_status,
          keyword,
          ids,
          token,
        };
        let data = urlEncode(params);

        window.location.href = BASE_URL2 + "/merapi/product/export?" + data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
      // this.$http2.get('/merapi/product/export',{ params:{ brand_id, cate_id, trade_type, stock_status, keyword, ids }}).then(res => {
      //   this.$message.success(res.data.msg)
      // }).catch(err => {
      //   this.$message.error(err + '')
      // })
    },
  },
};
</script>

<style scoped lang="less">
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    position: relative;
    font-weight: 600;
    justify-content: center;
  }

  .left {
    width: 25%;
    height: 180px;
  }

  .mid {
    width: 35%;
    height: 180px;
  }

  .right {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }

  .ico-left {
    width: 100%;
    color: #d2d6d9;
    font-size: 24px;
    position: absolute;
    top: 20px;
    left: 20px;
    text-align: left;
  }

  .ico-right {
    width: 100%;
    color: #d2d6d9;
    font-size: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: right;
  }

  .color-red {
    margin-bottom: 15px;
    color: #f71414;
    font-size: 26px;
  }

  .color-black {
    color: #000;
    font-size: 20px;
  }
}

.select {
  display: flex;
  align-items: center;
  margin-top: 57px;
  // justify-content: space-between;

  div {
    font-size: 16px;

    color: #222;
  }

  .el-button {
    margin-left: 30px;
    background-color: #0078fb;
    font-size: 16px;
    color: #fff;
  }
}

.htmlContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;

  img {
    width: 90%;
  }
}

.head_pic {
  width: 120px;
  height: 120px;
}

.item-group {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.input {
  display: flex;
  align-items: center;

  span {
    width: 90px;
    font-size: 16px;
  }

  .el-input {
    width: 250px;
    font-size: 14px;
  }
}

.el-button {
  margin-left: 40px;
}

.loadBox {
  margin-left: 100px;
  display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.table {
  margin-top: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0;

  align-items: center;
}
</style>
